import React from 'react';
import './App.css';
import saveAs from 'save-as'

class App extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            counter: 0,
            lastChangedAt: null
        };
    }

    componentDidMount() {
        // create the store
        this.props.db.version(1).stores({counterData: 'id,value'})

        // perform a read/write transatiction on the new store
        this.props.db.transaction('rw', this.props.db.counterData, async () => {
            // get the first and last name from the data
            const dbCounter = await this.props.db.counterData.get('counter')
            const dbLastChangedAt = await this.props.db.counterData.get('lastChangedAt')

            // if the first or last name fields have not be added, add them
            if (!dbCounter) await this.props.db.counterData.add({id: 'counter', value: 0})
            if (!dbLastChangedAt) await this.props.db.counterData.add({id: 'lastChangedAt', value: null})

            // set the initial values
            this.setState((state, props) => ({
                counter: dbCounter ? dbCounter.value : 0,
                lastChangedAt: dbLastChangedAt ? dbCounter.value : null
            }));
        }).catch(e => {
            // log any errors
            console.log(e.stack || e)
        });
    }

    componentWillUnmount() {
        this.props.this.props.db.close()
    }

    increment() {
        this.setState((state, props) => ({
            counter: state.counter + 1,
            lastChangedAt: new Date()
        }));
    }

    decrement() {
        this.setState((state, props) => ({
            counter: state.counter - 1,
            lastChangedAt: new Date()
        }));
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        this.updateDb();
    }

    updateDb() {
        this.props.db.counterData.put({ id: "counter", value: this.state.counter });
        this.props.db.counterData.put({ id: "lastChangedAt", value: this.state.lastChangedAt });
    }

    download()
    {
        let result = "Counter: " + this.state.counter + "\n";
        result += "LastChangedAt: " + (this.state.lastChangedAt != null?this.state.lastChangedAt.toLocaleString():"never");
        let blob = new Blob([result], {type: "text/plain;charset=utf-8"});
        saveAs(blob, "results.txt");
    }

    render() {
        return (
            <div>
                <header className="navbar navbar-expand navbar-dark flex-column flex-md-row counter-navbar">
                    <h1>Offline Counter Demo</h1>
                </header>
                <div id="main" className="container">
                    <div className="row align-items-center">
                        <div className="col-md-6 order-md-1 text-center text-md-left pr-md-5">
                            <div className="row mx-n2">
                                <div id="counter-value">
                                    {this.state.counter}
                                </div>
                            </div>
                            <div className="row mx-n2">
                                <div id="counter-last-changed-at">
                                    Last changed
                                    at: {this.state.lastChangedAt != null ? this.state.lastChangedAt.toLocaleString() : "never"}
                                </div>
                            </div>

                            <div className="col-md px-2">
                                <button onClick={() => this.increment()}
                                        className="btn btn-lg btn-success w-100 mb-3"
                                >
                                    Inkrement
                                </button>
                            </div>
                            <div className="col-md px-2">
                                <button onClick={() => this.decrement()}
                                        className="btn btn-lg btn-danger w-100 mb-3">
                                    Dekrement
                                </button>
                            </div>
                            <div className="col-md px-2">
                                <button onClick={() => this.download()}
                                        className="btn btn-lg btn-outline-secondary w-100 mb-3">
                                    Download Result
                                </button>
                            </div>
                            <div className="col-md px-2">
                                <p className="text-muted mb-0">
                                    Just a small demo &copy; 2019 by MK
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default App;
